.botones_wrapper a,
.total-label-link,
a {
  text-decoration: none;
}
:root {
  --f-family: "";
  --bg-body: #1b1b1b;
  --bg-app: #1d1e1d;
  --background-card: rgb(87, 87, 87);
  --color-card: rgb(168, 214, 168);
  --color-scrollbar-track: rgba(255, 255, 255, 0.1);
  --color-scrollbar-thumb: rgba(255, 255, 255, 0.3);
  --color-scrollbar-thumb-hover: rgba(255, 255, 255, 0.5);
  --color-text: white;
  --fs-1: 20px;
  --fs-2: 18px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  outline: 0;
}
body {
  font-family: var(--f-family);
  margin: 0;
  padding: 0;
}
.App {
    background-color: var(--bg-body);
  height: 100vh;
}
.app_container {
  width: 100%;
  max-width: 500px;
  height: 100%;
  background-color: var(--bg-app);
  margin: auto;
  padding: 16px;
  color: var(--color-text);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
}
.app_container input {
  min-height: 60px;
  font-size: 20px;
}
.nuevo_viaje {
  width: 100%;
}
.nuevo_viaje .btn_nuevo {
  width: 100%;
}
.nuevo_viaje a,
.nuevo_viaje button {
  font-size: 32px;
}
.nuevo_viaje button {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.viajes {
  flex: 1;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--bg-body) var(--bg-app);
}
.viajes .opciones {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.viajes .data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 80px;
  font-size: 20px;
  padding: 4px;
  position: relative;
}
.viajes .data .direccion {
  max-width: 65%;
}
.viajes .importe {
  display: flex;
  height: 100%;
  align-items: center;
  gap: 5px;
  position: absolute;
  top: 0;
  right: 0;
}
.viajes .data .importe-credit {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.viajes .importe_input {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.viajes .importe_input input[type="number"] {
  max-width: 110px;
  min-width: 80px;
  text-align: center;
}
.detalles .botones {
  display: flex;
  justify-content: center;
  gap: 16px;
}
.detalles .botones_wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.inputs_wrapper {
  display: grid;
  grid-template-columns: 5fr 3fr 1fr;
  gap: 4px;
}
.input_add .icon_voice {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
  color: #000;
}
.input_add .icon_voice.listening {
  color: red;
}
.no-outline:focus {
  outline: 0;
}
.input_add input {
  width: 100%;
  border: none;
  box-shadow: none;
  outline: 0;
}
.info_contacto .contacto_icons {
  font-size: 40px;
}
.totals-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.totals-container > div {
  padding: 0.6rem;
  box-shadow: inset 0 0 4px #303030;
  transition: box-shadow 0.3s;
  background-color: #242424;
  height: 100%;
}
.totals-container .total-value {
  font-weight: 500;
  font-size: 18px;
}
.total-label-link {
  color: inherit;
}
.ajustes .card_porcentaje {
  font-size: 20px;
  text-align: center;
}
.ajustes .card_porcentaje .input_item {
  display: flex;
  gap: 5px;
  align-items: flex-end;
  width: 100%;
  max-width: 150px;
  justify-content: space-between;
  margin: auto auto 10px;
}
.card_porcentaje .input_item input {
  max-width: 60px;
  text-align: center;
}
.card_porcentaje-header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.card_rol h4 {
  margin-bottom: 15px;
  font-size: 1.5rem;
}
.card_rol .radio-options {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.card_rol label {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  cursor: pointer;
}
.card_rol input[type="radio"] {
  margin-right: 10px;
}
.install-button-container {
  position: relative;
  display: flex;
}
